@use "styles/utils";

.about-author {
  padding: 24px 36px 26px;

  &__body-wrapper {
    display: flex;
    gap: 30px;
  }

  &__img {
    @include utils.circle(112px);
    flex-shrink: 0;
    background-color: var(--blue100);
    border: 3px solid var(--blue200);
  }

  &__name {
    font-size: 18px;
    font-weight: 700;
    color: var(--blue700);
    line-height: 1.23;
  }

  &__proffesion {
    margin-bottom: 15px;
    color: var(--gray100);
  }

  &__text{
    color: var(--gray100);
  }

  &__link {
    margin-top: 14px;
    display: block;
    font-weight: 700;
    color: var(--blue600);
    @include utils.transition(color);

    &:hover {
      color: var(--blue300);
    }
  }
}

@media only screen and (max-width: 700px) {
  .about-author {
    padding: 20px;

    &__body-wrapper {
      gap: 23px;
    }

    &__img {
      --diameter: 70px;
    }
  }
}

@media only screen and (max-width: 450px) {
  .about-author {
    &__body-wrapper {
      flex-direction: column;
      gap: 10px;
    }
  }
}