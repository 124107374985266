@use "styles/utils";

.blog-container {
  margin: 0 auto;
  padding: 0 15px;
  max-width: 1330px;
  font-family: 'Open sans', var(--sans);
  color: var(--gray200);
  font-size: 16px;
  line-height: 1.62;
}

.main-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.main {
  flex: 1 1 0;
}

html.disable-scroll {
  overflow: clip;

  body {
    overflow: clip;
  }
}

.swiper,
.swiper-wrapper,
.swiper-slide {
  box-sizing: border-box !important;
}

@media only screen and (max-width: utils.$desktop1460) {
  .blog-container {
    padding: 0 50px;
  }
}

@media only screen and (max-width: 1200px) {
  .blog-container {
    font-size: 14px;
  }

  ._desktop {
    display: none !important;
  }
}

@media only screen and (min-width: 1200px) {
  ._mobile {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .blog-container {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 340px) {
  .blog-container {
    padding: 0 15px;
  }
}