@use "styles/utils";
@use 'ui/buttons/rating-btn';

.comment {
  &__body-wrapper {
    display: flex;
  }

  &__img {
    margin-right: 18px;
    @include utils.circle(64px);
    flex-shrink: 0;
    background-color: var(--blue100);
    border: 3px solid var(--blue200);
  }

  &__body {
    margin-right: 28px;
  }

  &__name {
    font-weight: 600;
  }

  &__text {
    margin-bottom: 6px;
  }

  &__btn {
    font-size: 15px;
    font-weight: 700;
    color: var(--blue600);
  }

  &__rating {
    margin-left: auto;
    margin-top: 3.2%;
    height: fit-content;
    @include utils.items-center;
    gap: 10px;
  }

  &__rating-num {
    color: var(--blue600);
    font-weight: 600;
  }

  &__replies {
    margin-top: 20px;
    padding-left: 50px;
    width: 100%;
    @include utils.flex-col;
    gap: 16px;

    .comment {
      width: 100%;

      &__img {
        --diameter: 45px;
      }

      &__rating {
        margin-top: 15px;

        .rating-btn {
          @include utils.square(37px);

          &::after {
            @include utils.square(18px)
          }
        }
      }

      &__rating-num {
        font-size: 14px;
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .comment {
    &__img {
      margin-right: 15px;
      --diameter: 50px;
    }

    &__body {
      margin-right: 20px;
    }

    &__rating {
      gap: 8px;
    }

    &__replies {
      margin-top: 17px;
      padding-left: 25px;

      .comment {
        .rating-btn {
          @include utils.square(26px);

          &::after {
            @include utils.square(15px)
          }
        }
      }
    }

    &__btn {
      font-size: 13px;
    }
  }
}

@media only screen and (max-width: 500px) {
  .comment {
    &__img {
      display: none;
    }

    &__rating {
      display: none;
    }
  }
}