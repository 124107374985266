@use "styles/utils";
@use "parts/comment";

.comments {
  padding: 23px 36px 34px;

  &__title {
    margin-bottom: 17px;
    font-weight: 700;
    font-size: 23px;
    line-height: 1.395;
  }

  &__form {
    margin-bottom: 30px;

    .input {
      margin-bottom: 7px;
    }

    .textarea {
      height: 96px;
      padding-right: 65px;
    }
  }

  &__textarea-wrapper {
    position: relative;

    .submit-btn {
      position: absolute;
      right: 16px;
      top: 16px;
    }
  }

  &__list {
    @include utils.flex-col;
    gap: 32px;
  }
}

@media only screen and (max-width: 700px) {
  .comments {
    padding: 20px;

    &__form {
      margin-bottom: 20px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .comments {
    &__textarea-wrapper {

      .submit-btn {
        right: 12px;
        top: 12px;
      }
    }
  }
}