@use "styles/utils" as *;

.header {
  @include flex-col;
  margin-bottom: 55px;

  &__top {
    width: 100%;
    background-color: var(--blue100);
    .blog-container {
      @include py(15px);
      width: 100%;
      @include flex-between;
      align-items: center;
      line-height: 1.6;
      
    }
    
    a {
      font-size: 15px;
      @include hover;
    }
    span{
      font-size: 15px;

    }
  }

  &__body {
    background-color: var(--white);
    @include bd-y(var(--blue200), 2px);
    padding: 16px 0;

    .blog-container {
      @include flex-between;
      @include items-center;
    }
  }

  &__logo {
    display: block;
    width: 176px;
    height: 64px;
    flex-shrink: 0;

    img {
      @include img-size(100%, 100%, contain)
    }
  }

  &__menu-list {
    @include clear-list;
    @include flex-start-center;
    gap: 40px;
    font-weight: 600;
    line-height: 1.25;
    text-transform: uppercase;
  }

  &__menu-item {
    @include hover;
  }

  &__consult-btn {
    color: var(--blue600);
    font-weight: 700;
    text-transform: uppercase;
    @include transition(color);

    &:hover {
      color: var(--blue700);
    }
  }

  .menu-btn {
    z-index: 12;
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
  .header {
    &__top .blog-container {
      @include py(10px);
      display: grid;
      grid-template-columns: 1fr 1fr;
      max-width: 800px;
      gap: 9px 15px;
      justify-items: center;
    }

    &__body {
      padding: 10px 0;
    }

    &__logo {
      width: 140px;
      height: 54px;
    }

    &__menu-list {
      gap: 20px;
    }
  }
}

@media only screen and (max-width: 880px) {
  .header {
    position: relative;

    &__menu {
      position: absolute;
      left: 0;
      top: 100%;
      z-index: 10;
      padding: 30px;
      width: 100%;
      height: 100vh;
      background-color: var(--white);
      opacity: 0;
      visibility: hidden;
      @include transition((opacity, visibility));

      &._active {
        opacity: 1;
        visibility: visible;
      }
    }

    &__menu-list {
      flex-direction: column;
    }

    &__consult-btn {
      @include mx(auto, 30px);
      font-size: 13px;
    }

    .menu-btn {
      display: flex;
    }
  }
}

@media only screen and (max-width: 530px) {
  .header {
    &__consult-btn {
      margin-right: 20px;
      @include square(24px);
      @include bg-img('media/static/icons/tel.svg');
      font-size: 0;
    }
  }
}

@media only screen and (max-width: 450px) {
  .header {
    &__top {
      display: none;
    }
  }
}