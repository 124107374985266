@use "styles/utils";

.tg-promo {
  padding: 25px 46px;
  width: 100%;
  @include utils.items-center;
  background-color: var(--blue500);
  border-radius: 14px;
  color: var(--white);

  &__img {
    margin-right: 49px;
    @include utils.square(96px);
  }

  &__body {
    margin-right: 20px;
  }

  &__title {
    margin-bottom: 6px;
    max-width: 360px;
    font-size: 22px;
    font-weight: 800;
    text-transform: uppercase;
  }

  &__text {
    font-size: 17px;
    font-weight: 500;
  }

  &__btn {
    margin-left: auto;
    padding: 13px 23px;
    border-radius: 8px;
    border: 2px solid var(--white);
    font-weight: 700;
    line-height: 1.15;
    @include utils.transition((background-color, color));

    &:hover {
      background-color: var(--white);
      color: var(--blue500);
    }
  }
}

@media only screen and (max-width: 960px) {
  .tg-promo {
    padding: 25px;

    &__img {
      margin-right: 40px;
      @include utils.square(77px);
    }

    &__title {
      font-size: 18px;
    }

    &__text {
      font-size: 15px;
    }
  }
}

@media only screen and (max-width: 700px) {
  .tg-promo {
    padding: 25px;
    display: grid;
    grid-template-columns: 1fr 8fr;
    grid-template-rows: 2fr 1fr;
    gap: 15px 30px;

    &__img {
      margin-right: 0;
    }

    &__body {
      margin-right: 0;
    }

    &__btn {
      grid-column: span 2;
      width: 100%;
      text-align: center;
    }
  }
}

@media only screen and (max-width: 450px) {
  .tg-promo {
    padding: 20px;
    gap: 15px 17px;

    &__img {
      margin-top: 10px;
      @include utils.square(40px);
      align-self: start;
    }

    &__title {
      font-size: 15px;
    }

    &__text {
      font-size: 13px;
    }
  }
}

@media only screen and (max-width: 340px) {
  .tg-promo {
    display: flex;
    flex-direction: column;

    &__img {
      display: none;
    }
  }
}