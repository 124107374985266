@use "styles/utils";

.article-author {
  @include utils.items-center;
  gap: 18px;

  &__img {
    @include utils.circle(64px);
    flex-shrink: 0;
    overflow: hidden;
    border: 3px solid var(--blue200);
    background-color: var(--blue100);

    img {
      @include utils.img-size(100%, 100%);
    }
  }

  &__inner {
    line-height: 1;
  }

  &__title {
    margin-bottom: 6px;
    font-weight: 600;
    color: var(--blue700);
  }

  &__subtitle {
    font-size: 14px;
    color: var(--gray100);
  }
}