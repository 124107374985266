@use "styles/utils";
@use "components/article-stat";
@use "components/article-author";
@use "components/article-actions";

.article-item {
  padding: 24px 36px 20px;

  &__top {
    margin-bottom: 17px;
    @include utils.flex-between;
    @include utils.items-center
  }

  &__stats {
    display: flex;
    gap: 20px;

    .article-stat {
      margin-top: 7px;
    }
  }

  &__title {
    margin-bottom: 12px;
    display: block;
    font-size: 22px;
    font-weight: 700;
    line-height: 1.275;
    @include utils.hover;
  }

  &__text {
    margin-bottom: 24px;
  }

  &__img {
    margin: 0 -36px;
    height: 215px;

    img {
      @include utils.img-size(100%, 100%)
    }
  }

  &__bottom {
    padding-top: 20px;
    @include utils.flex-between;
    @include utils.items-center;
  }

  &__info {
    display: flex;
    gap: 20px;
    font-size: 15px;
  }
}

@media only screen and (max-width: 1400px) {
  .article-item {
    padding: 20px;

    &__img {
      margin: 0 -20px;
    }

    &__stats {
      gap: 10px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .article-item {
    .article-stat__title {
      display: none;
    }

    &__title {
      font-size: 17px;
    }

    .article-actions {
      gap: 10px;

      &__item {
        font-size: 0;
      }
    }

    &__top {
      gap: 15px;
    }

    .article-author {
      gap: 9px;

      &__img {
        --diameter: 50px;
      }
    }

    &__stats {
      flex-direction: column;
      gap: 2px;

      .article-stat {
        margin-top: 0;
      }
    }

    .article-stat {
      &__value {
        gap: 3px;
        font-size: 12px;

        &::before {
          @include utils.square(20px);
        }
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  .article-item {
    padding: 18px;

    &__img {
      margin: 0 -18px;
    }
  }
}

@media only screen and (max-width: 350px) {
  .article-item {
    &__bottom {
      flex-direction: column-reverse;
      gap: 15px;
    }
  }
}