@use "styles/utils" as *;

.icon {
  @include items-center;
  gap: 6px;

  &::before {
    content: '';
    @include square(24px);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    flex-shrink: 0;
  }
}

@mixin icon($name) {
  .icon._#{$name} {
    @extend .icon;

    &::before {
      background-image: url('media/static/icons/#{$name}.svg');
    }

    @content;
  }
}

@include icon('tel');
@include icon('geo');
@include icon('clock');
@include icon('mail');
@include icon('eye');
@include icon('chat');
@include icon('share');
@include icon('bookmark');
@include icon('like');
@include icon('calendar');
@include icon('print');