@use "styles/utils";

.article-actions {
  display: flex;
  gap: 20px;

  &__item {
    gap: 8px;
    font-weight: 700;
    color: var(--blue700);
    text-transform: uppercase;
    font-size: 14px;
    filter: var(--blue700_filter);
    @include utils.transition(filter);

    &:hover {
      filter: var(--blue600_filter);
    }
  }
}