@use 'media' as *;

@mixin reset-list($display: none) {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin clear-list {
  @include reset-list;
  li {
    padding-left: 0;
    text-indent: initial;
    color: var(--body_font);

    &::before {
      display: none;
    }
  }
  li + li {
    margin-top: 0;
  }
}

@mixin transition($property, $duration: .3s, $timing: ease-in-out) {
  transition-property: $property;
  transition-timing-function: $timing;
  transition-duration: $duration;
}

@mixin circle($diameter) {
  --diameter: #{$diameter};
  width: var(--diameter);
  height: var(--diameter);
  border-radius: 50%;
}

@mixin adaptive-circle($diameter) {
  @include adaptive(--diameter, $diameter);
  width: var(--diameter);
  height: var(--diameter);
  border-radius: 50%;
}

@mixin adaptive-fz($property, $initialValue, $mod: 1, $unit: px, $min: 1.2rem, $max: 900rem) {
  $value: calc(#{$initialValue} * #{$mod} * var(--screen-dif) + #{$initialValue} * 1#{$unit});
  #{$property}: clamp(#{$min}, #{$value}, #{$max});
}

@mixin adaptive($property, $initialValue, $mod: 1, $unit: px) {
  #{$property}: calc(#{$initialValue} * #{$mod} * var(--screen-dif) + #{$initialValue} * 1#{$unit})
}

@mixin hover {
  @include transition(color);

  &:hover {
    color: var(--blue600);
  }
}