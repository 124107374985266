@use "styles/utils";
@use "global/components/popular";
@use "global/components/recommendations";
@use "parts/article-item";

.articles-list {
  .blog-container {
    display: flex;
    gap: 30px;
  }

  .popular,
  .recommendations {
    position: sticky;
    top: 10px;
    max-width: 300px;
    width: 100%;
    height: fit-content;
    flex-shrink: 0;
  }

  &__body {
    margin-top: 49px;
    @include utils.flex-col;
    gap: 16px;
  }
}

@media only screen and (max-width: 1200px) {
  .articles-list {
    .blog-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .popular,
    .recommendations {
      position: initial;
    }

    &__body {
      grid-column: span 2;
      grid-row: 2;
      margin-top: 0;
    }
  }
}

@media only screen and (max-width: 700px) {
  .articles-list {
    .blog-container {
      display: flex;
      flex-direction: column;
    }

    &__body {
      order: 3;
    }
  }
}