.blog-title {
  font-weight: 700;
  font-size: 23px;
  line-height: 1.4;
}

.article-block-title {
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.6;
  text-transform: uppercase;
}

@media only screen and (max-width: 700px) {
  .article-block-title {
    font-size: 18px;
  }
}