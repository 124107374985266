@use "styles/utils";
@use "sections/article-detail";
@use "sections/docs";
@use "sections/about-author";
@use "sections/comments";
@use "sections/other-articles";
@use "components/tg-promo";

.article-detail-wrapper .blog-container {
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 24px 30px;

  & > * {
    grid-column: 2;
  }

  .table-of-content {
    position: sticky;
    top: 15px;
    width: 100%;
    height: fit-content;
    grid-column: 1;
    flex-shrink: 0;
  }
}

@media only screen and (max-width: utils.$tablet1200) {
  .article-detail-wrapper .blog-container {
    grid-template-columns: 1fr;

    & > * {
      grid-column: 1;
    }

    .table-of-content {
      position: initial;
    }
  }
}