@use "styles/utils";

.scroll-top-btn {
  position: fixed;
  bottom: 25px;
  right: calc((100vw - 1300px) / 2);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 7px;
  font-size: 14px;
  font-weight: 700;
  line-height: calc(24 / 14);
  text-transform: uppercase;
  text-align: center;
  color: var(--blue600);
  opacity: 0;
  @include utils.transition((filter, opacity));

  span {
    @include utils.circle(64px);
    @include utils.flex-center2x;
    background-color: var(--blue600);

    &::after {
      content: '';
      @include utils.square(50%);
      display: block;
      @include utils.bg-img('media/static/icons/full-arrow.svg');
    }
  }

  &:hover {
    filter: brightness(1.4) saturate(0.7);
  }
}

@media only screen and (max-width: 1440px) {
  .scroll-top-btn {
    right: 50px;
  }
}

@media only screen and (max-width: 1100px) {
  .scroll-top-btn {
    font-size: 0;

    span {
      box-shadow: 0 4px 32px rgba(21, 47, 80, 0.6);
    }
  }
}

@media only screen and (max-width: 600px) {
  .scroll-top-btn {
    right: 20px;
    bottom: 10px;

    span {
      --diameter: 50px;
    }
  }
}