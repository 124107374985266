@use "styles/utils";

.input {
  padding: 9px 16px;
  width: 100%;
  border: 1px solid var(--blue300);
  border-radius: 10px;
  color: var(--gray200);
  font-size: 15px;
  outline: solid transparent;
  @include utils.transition(outline);

  &:focus {
    outline: solid var(--blue300);
  }

  &::placeholder {
    color: var(--gray100);
  }
}

@media only screen and (max-width: 600px) {
  .input {
    padding: 7px 12px;
    font-size: 13px;
  }
}