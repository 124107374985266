@use "styles/utils";

.breadcrumbs {
  @include utils.clear-list;
  @include utils.items-center;
  flex-wrap: wrap;

  &__item {
    font-size: 15px;
    line-height: 1.6;
    color: var(--gray100);

    a {
      text-decoration: underline;
      text-decoration-color: transparent;
      @include utils.transition(text-decoration-color);

      &:hover {
        text-decoration-color: var(--gray100);
      }
    }
  }

  &__item + &__item {
    @include utils.items-center;

    &::before {
      content: '';
      @include utils.mx(5px);
      @include utils.square(20px);
      display: inline-block;
      @include utils.bg-img('media/static/icons/arrow.svg')
    }
  }
}