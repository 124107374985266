@use "styles/utils";

.menu-btn {
  height: 17px;
  width: 29px;
  @include utils.flex-between;
  @include utils.flex-col;

  span {
    width: 100%;
    height: 3px;
    background-color: var(--blue600);
    border-radius: 4px;
    @include utils.transition((opacity));
  }

  &._active {
    position: relative;
    @include utils.flex-center2x;

    span{
      position: absolute;

      &:nth-child(1) {
        rotate: 45deg;
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(3) {
        rotate: -45deg;
      }
    }
  }
}