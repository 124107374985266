.flex {
  --display: flex;
  --flex-direction: row;
  --justify-content: initial;
  --align-items: initial;
  display: var(--display);
  flex-direction: var(--flex-direction);
  justify-content: var(--justify-content);
  align-items: var(--align-items);
}

@mixin items-center {
  @extend .flex!optional;
  --align-items: center;
}

@mixin flex-between {
  @extend .flex!optional;
  --justify-content: space-between;
}

@mixin flex-center {
  @extend .flex!optional;
  --justify-content: center;
}

@mixin flex-start-center {
  @extend .flex;
  --justify-content: start;
  --align-items: center;
}

@mixin flex-center2x {
  @extend .flex;
  --justify-content: center;
  --align-items: center;
}

@mixin flex-col {
  @extend .flex;
  --flex-direction: column;
}

@mixin flex-col-center {
  @extend .flex;
  --flex-direction: column;
  --align-items: center;
}

@mixin flex-col-center2x {
  @extend .flex;
  --flex-direction: column;
  --align-items: center;
  --justify-content: center;
}

@mixin py($y, $top: false) {
  padding-top: if($top, top, $y);
  padding-bottom: #{$y};
}

@mixin px($x, $right: false) {
  padding-right: if($right, $right, $x);
  padding-left: #{$x};
}

@mixin my($y, $top: false) {
  margin-top: if($top, top, $y);
  margin-bottom: #{$y};
}

@mixin mx($x, $right: false) {
  margin-right: if($right, $right, $x);
  margin-left: #{$x};
}

@mixin bd-y($color, $y, $bottom: false) {
  border: solid #{$color};

  @if $bottom {
    border-width: #{$y} 0 #{$bottom};
  } @else {
    border-width: #{$y} 0;
  }
}

@mixin bd-x($color, $x, $right: false) {
  border: solid #{$color};

  @if $right {
    border-width: 0 #{$right} 0 #{$x};
  } @else {
    border-width: 0 #{$x};
  }
}

@mixin custom-scroll($width, $track-color, $thumb-color, $height: false) {
  scrollbar-width: #{$width};
  scrollbar-color: #{$thumb-color};

  &::-webkit-scrollbar {
    width: #{$width};

    @if $height {
      height: #{$height};
    }
  }


  &::-webkit-scrollbar-thumb {
    background-color: #{$thumb-color};
  }

  &::-webkit-scrollbar-track {
    background-color: #{$track-color};
  }
}

@mixin transparent-scroll() {
  @include custom-scroll(0, transparent, transparent, 0);
}

.object-fit {
  --object-fit: initial;
  --object-position: center;
  object-fit: var(--object-fit);
  object-position: var(--object-position);
}

@mixin object-cover {
  @extend .object-fit;
  --object-fit: cover;
}

@mixin object-contain {
  @extend .object-fit;
  --object-fit: contain;
}

@mixin img-size($w, $h, $fit: cover) {
  width: #{$w};
  height: #{$h};

  @if $fit == cover {
    @include object-cover;
  } @else if $fit == contain {
    @include object-contain;
  } @else {
    --object-position: center;
    object-position: var(--object-position);
    object-fit: #{$fit};
  }
}

@mixin square($size) {
  width: #{$size};
  height: #{$size};
}

@mixin bg-img($path, $size: contain, $pos: center, $repeat: no-repeat) {
  background-image: url(#{$path});
  background-size: #{$size};
  background-position: #{$pos};
  background-repeat: #{$repeat};
}

@mixin tab {
  display: grid;
  grid-auto-rows: 1fr 0;
  overflow: hidden;

  &._active {
    grid-auto-rows: auto;
  }

  &._active &__title::after {
    scale: -1;
  }

  &__title {
    &::after {
      content: '';
      position: relative;
      top: 5px;
      margin-left: 5px;
      display: inline-block;
      @include square(24px);
      @include bg-img('media/static/icons/arrow.svg');
      rotate: 90deg;
      filter: var(--blue700_filter);
    }
  }
}
