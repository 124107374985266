@use "styles/utils" as *;
@use "sections/short_description";
@use "sections/description";
@use "sections/author_articles" ;
@use "node_modules/swiper/swiper";
@use "node_modules/@fancyapps/ui/src/Fancybox/Fancybox";
.author{
    display: grid;
    gap: 30px;
    grid-template-columns: 1fr 50% 1fr;
    @media screen and (max-width: $tablet1200) {
        grid-template-columns: 1fr;
        display: block;
        gap: 0;
        &__content{
            grid-row: 3;
        }
    }
}

.recommendations{
    margin-top: 20px;
}

.main{
    background-color:var(--blue100);
    padding-bottom: 88px;
    padding-top: 55px;
    margin-top: -55px;
}