@use "styles/utils" as *;

.footer {
  background-color: var(--white);
  margin-top: 32px;
  a {
    text-decoration: none;
    border: none;
    text-transform: none;
  }
  p {
    text-align: start;
  }
  p + p {
    margin-top: 0;
  }
  a:hover {
    border: none;
  }
  &__first {
    margin-top: 24px;
  }
  &__top {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
  }
  &__phone-number {
    font-size: 19px;
    width: 100%;
    font-weight: 700;
    color: var(--gray100);
    display: block;
    line-height: 32px;
  }
  &__phone-numbers {
    margin-top: 17px;
  }
  &__working-time {
    margin-top: 13px;
  }
  &__email {
    margin-top: 16px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: var(--blue600);
    display: block;
    text-align: start;
  }
  &__working-time {
    color: var(--gray100);
    font-size: 15px;
  }
  &__address {
    font-size: 16px;
    font-weight: 500;
  }
  &__email:hover {
    color: var(--blue700);
  }
  &__sections {
    margin-top: 34px;
    display: grid;
    grid-template-columns: 25% 1fr;
  }
  &__section-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  &__service {
    columns: 2;
  }
  &__section-list-item {
    margin-bottom: 7px;
    
    &:last-child {
        margin-bottom: 0;
    }
  }
  &__section-list-item a {
    font-size: 16px;
    line-height: 22px;
    color: var(--gray100);
  }

  &__section-title {
    padding-bottom: 8px;
    display: flex;
    gap: 2px;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-transform: uppercase;
    cursor: pointer;
    align-items: center;
    img {
      display: none;
    }
  }
  &__actions {
    margin-top: 37px;
  }
  &__subtitle {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    text-transform: uppercase;
  }
  &__subtitle + p {
    color: var(--gray100);
    text-align: center;
    font-size: 16px;
    line-height: 24px;
  }
  &__messenger-btn {
    border-radius: 100px;
    display: flex;
    width: max-content;
    align-items: center;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 32px;
    padding-right: 32px;
    gap: 10px;
    font-size: 15px;
    font-weight: 700;
    color: var(--white);
    line-height: 24px;
    &:hover{
      color: var(--white);
    }
    img {
      width: 24px;
      height: 24px;
    }
  }
  .telegram {
    background-color: #20a0e1;
    margin-top: 18px;
  }
  .whatsapp {
    background-color: #51c85d;
    margin-top: 16px;
  }
  &__btn-wrapper {
    display: flex;
    justify-content: center;
  }
  &__bottom-wrapper {
    background-color: var(--blue100);
  }
  &__bottom {
    font-size: 15px;
    line-height: 24px;
    margin-top: 50px;
    padding-top: 24px;
    padding-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
      font-weight: 600;
      font-size: 15px;
      color: var(--blue600);
      text-transform: none;
    }
    a:hover {
      color: var(--blue700);
    }
  }
  @media screen and (max-width: $tablet800) {
    &__first {
      margin-top: 24px;
    }
    &__phone-numbers {
      text-align: center;
    }
    &__logo {
      display: flex;
      justify-content: center;
    }
    &__email {
      text-align: center;
    }
    &__service {
      columns: 1;
    }
    p {
      text-align: center;
    }
    .blog-container {
      display: flex;
      flex-direction: column;
    }
    &__email {
      text-align: center;
    }
    &__sections {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    &__section-list {
      display: none;
      margin: 0;
      padding: 0;
      padding-bottom: 18px;
      list-style: none;
    }
    &__section-list-item {
      text-align: center;
    }
    &__section {
      border-radius: 14px;
      border: 1px solid var(--blue600);
    }
    &__section-title {
      align-items: center;
      justify-content: center;
      padding-bottom: 16px;
      padding-top: 16px;
      img {
        display: block;
      }
    }
    &__actions {
      margin-top: 37px;
    }
    &__bottom {
      display: flex;
      flex-direction: column;
      background-color: var(--white);
      a {
        text-align: center;
      }
    }
  }
}

.footer__section-active {
  .footer__section-list {
    display: block;
  }
  .footer__section-title {
    padding-bottom: 12px;
  }
}

@media only screen and (max-width: 900px) {
  .footer {
    margin-top: 24px;
  }
}
