@use "styles/utils";

.submit-btn {
  @include utils.circle(40px);
  @include utils.flex-center2x;
  background-color: var(--blue600);
  border: 3px solid transparent;
  @include utils.transition((background-color, border-color));

  &::after {
    content: '';
    position: absolute;
    @include utils.square(24px);
    @include utils.bg-img('media/static/icons/full-arrow.svg')
  }

  &:hover {
    background-color: var(--blue400);
  }

  &:focus {
    border-color: var(--gray200);
  }
}

@media only screen and (max-width: 600px) {
  .submit-btn {
    --diameter: 30px;

    &::after {
      @include utils.square(18px);
    }
  }
}