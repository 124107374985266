.author-articles {
  border-radius: 12px;
  border: 3px solid var(--blue200);
  background-color: var(--white);
  margin-top: 24px;
  padding: 30px 42px 34px 42px;
  &__title {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-transform: uppercase;
    color: var(--blue700);
    text-align: center;
  }
  &__list{
    margin-top: 8px;
  }
  &__article-item + &__article-item{
    margin-top: 16px;
  }
  &__article-item{
    display: grid;
    gap: 6px;
    grid-template-columns: 24px 1fr;
  }
  &__article-item::before{
    content: "";
    width: 24px;
    display: block;
    height: 24px;
    background-image: url("media/static/icons/double_arrows.svg");
  }
  ul{
    list-style: none;
    padding-inline-start: 0;
    margin-block-start: 0;
    margin-block-end: 0;
  }
}
