@use "styles/utils";

.rating-btn {
  @include utils.square(46px);
  @include utils.flex-center2x;
  border-radius: 8px;
  border: 1px solid var(--blue300);
  @include utils.transition(background-color);

  &::after {
    content: '';
    @include utils.square(21px);
    display: block;
    filter: brightness(1);
    @include utils.bg-img('media/static/icons/like.svg');
    @include utils.transition(filter);
  }

  &._dislike::after {
    scale: -1;
  }

  &:hover {
    background-color: var(--blue200);

    &::after {
      filter: brightness(100);
    }
  }

  &._active {
    background-color: var(--blue300);

    &::after {
      filter: brightness(100);
    }
  }
}

@media only screen and (max-width: 700px) {
  .rating-btn {
    @include utils.square(35px);

    &::after {
      @include utils.square(16px);
    }
  }
}