@use "styles/utils";

.recommendations {
  &__title {
    margin-bottom: 17px;
  }

  &__list {
    @include utils.flex-col;
    gap: 8px;
  }

  &__item {
    padding: 20px 24px;
    display: block;

    &:hover .recommendations__item-title {
      color: var(--blue600);
    }
  }

  &__item-title {
    font-weight: 600;
    color: var(--blue700);
    line-height: 1.5;
    margin-bottom: 3px;
    @include utils.transition(color);
  }

  &__item-info {
    @include utils.flex-between;
    @include utils.items-center;
    font-size: 14px;
  }
}

@media only screen and (max-width: 1200px) {
  .recommendations {
    @include utils.tab;
  }
}