@use "styles/utils";

.table-of-content {
  &__title {
    font-size: 23px;
    font-weight: 700;
    line-height: 1.4;
    margin-bottom: 17px;
  }

  &__list {
    @include utils.flex-col;
    gap: 6px;
  }

  &__item {
    padding: 19px 24px;
    @include utils.transition(background-color);

    &:hover {
      background-color: var(--blue100);
    }
  }

  &__item-title {
    font-size: 14px;
    font-weight: 700;
    color: var(--blue400);
    text-transform: uppercase;
  }

  &__item-value {
    font-weight: 600;
    color: var(--blue700);
    line-height: initial;
    text-transform: capitalize;
  }
}

@media only screen and (max-width: 1200px) {
  .table-of-content {
    @include utils.tab;
  }
}