// Fonts
:root {
  --sans: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Noto Sans', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
}

// Colors
:root {
  --white: #ffffff;
  --gray100: #4F4F4F;
  --gray200: #333333;
  --blue100: #EDF2FB;
  --blue200: #D7E3FC;
  --blue300: #B6CCFE;
  --blue400: #ABC4FF;
  --blue500: #039BE5;
  --blue600: #023E8A;
  --blue700: #003049;
}

// Filters
:root {
  --blue600_filter: brightness(0) saturate(100%) invert(15%) sepia(33%) saturate(6478%) hue-rotate(206deg) brightness(96%) contrast(98%);
  --blue700_filter: brightness(0) saturate(100%) invert(14%) sepia(61%) saturate(1350%) hue-rotate(169deg) brightness(92%) contrast(103%);
}