@use "styles/utils";

.popular {
  &__title {
    margin-bottom: 17px;
  }

  &__list {
    @include utils.clear-list;
    @include utils.flex-col;
    gap: 8px;
  }

  &__item > * {
    padding: 15px 32px;
    display: block;
    font-size: 16px;
    font-weight: 600;
    color: var(--blue700);

    &:hover {
      @include utils.transition(background-color);
      background-color: var(--blue100);
    }
  }
}

@media only screen and (max-width: 1200px) {
  .popular {
    @include utils.tab;
  }
}