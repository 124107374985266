@use "styles/utils";

.docs {
  padding: 24px 36px 28px;

  &__list {
    @include utils.clear-list;
    @include utils.flex-col;
    gap: 12px;

    li {
      width: fit-content;
      display: flex;
      gap: 10px;
      color: var(--blue600);
      font-weight: 600;
      @include utils.transition(color);

      &::before {
        content: '';
        flex-shrink: 0;
        position: relative;
        top: 1px;
        @include utils.square(24px);
        display: block;
        @include utils.bg-img('media/static/icons/checkbox.svg')
      }

      &:hover {
        color: var(--blue400);
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .docs {
    padding: 20px;

    &__list {
      li::before {
        @include utils.square(20px);
      }
    }
  }
}