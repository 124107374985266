@use "styles/utils";

.article-stat {
  @include utils.flex-col-center;
  font-size: 14px;


  &__title {
    text-transform: uppercase;
    color: var(--blue400);
    font-weight: 700;
    line-height: 1.45;
  }

  &__value {
    line-height: 1;
  }
}