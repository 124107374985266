.description{
    border-radius: 12px;
    border: 3px solid var(--blue200);
  background-color: var(--white);

    margin-top: 24px;
    padding: 0 42px;
    &__title{
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        text-transform: uppercase;
        margin-top: 30px;
        color: var(--blue700);
    }
    &__title:last-child{
        margin-top: 32px;
    }
    &__text{
        margin-top: 8px;
        font-size: 15px;
        line-height: 24px;
        color: var(--gray100);
    }
    .swiper{
        margin-top: 10px;
    }
    .swiper-button-prev img{
        transform: rotate(-180deg);
    }
    .swiper-pagination-bullet{
        display: block;
        border-radius: 50%;
        width: 12px;
        height: 12px;
        border: 1px solid var(--blue700);
    }
    .swiper-pagination{
        display: flex;
        gap: 10px;
    }
    .swiper-pagination-bullet-active{
        background-color: var(--blue700);
    }
}
.certs-nav{
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 30px;
    margin-top: 14px;
    margin-bottom: 32px;
    &__btn{
        display: flex;
        align-items: center;
    }
    &__wrapper{
        width: 100%;
        display: flex;
    }
    &__wrapper:first-child{
        justify-content: end;
    }
}
.cert-slide{
    border-radius: 12px;
    border: 1px solid var(--blue300);
    height: 152px!important;
    padding: 10px;
    img{
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
}
