@use "styles/utils";

.article-card {
  padding: 31px 36px 32px;

  &__category {
    margin-bottom: 2px;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--blue600);
  }

  &__title {
    margin-bottom: 9px;
    font-weight: 700;
    font-size: 22px;
  }

  &__text {
    margin-bottom: 26px;
    color: var(--gray100);
  }

  &__bottom {
    @include utils.flex-between;
    @include utils.items-center;
  }

  &__info {
    display: flex;
    gap: 32px;
  }

  &__num {
    font-size: 14px;
    color: var(--gray100);
  }
}

@media only screen and (max-width: 720px) {
  .article-card {
    padding: 25px;

    &__category {
      font-size: 12px;
    }

    &__title {
      font-size: 18px;
    }

    &__info {
      gap: 12px;
    }

    .article-actions {
      gap: 8px;

      &__item {
        font-size: 0;
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .article-card {
    &__bottom {
      flex-direction: column-reverse;
      gap: 15px;
    }
  }
}