@use "styles/utils" as *;
@use "node_modules/@fancyapps/ui/src/Fancybox/Fancybox";

.avatar{
    position: relative;
    width: 136px;

    &__image{
        width: 136px;
        height: 136px;
        border-radius: 50%;
        border: 3px solid var(--blue200);
        overflow: hidden;
    }
    img{
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
    &_no-image{
        background-color: var(--blue100);
    }
    &__title{
        position: absolute;
        padding-top: 10px;
        padding-bottom: 10px;
        color: var(--white);
        font-size: 15px;
        bottom: -8px;
        font-weight: 500;
        width: calc(100% + 4px);
        transform: translate(-2px, 0);
        border-radius: 8px;
        text-align: center;
        background-color: var(--blue600);
    }
}

.short-description{
    padding: 32px 0;
    border: 3px solid var(--blue200);
  background-color: var(--white);

    border-radius: 12px;
    display: flex;
    flex-direction: column;
    margin-top: 18px;
    align-items: center;
    &__title{
        color: var(--blue700);
        font-size: 20px;
        font-weight: 700;
        line-height: 32px;
        margin-top: 24px;
    }
    &__text{
        color: var(--gray100);
        text-align: center;
        font-size: 16px;
        line-height: 25px;
        margin-top: 5px;
        width: calc(100% - 160px);
    }
    @media screen and (max-width: $tablet800) {
        &__text{
            width: calc(100% - 60px);
        }
    }
}