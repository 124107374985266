@use "styles/utils";
@use "parts/table-of-content";
@use "global/components/breadcrumb/index.scss";

.article-detail {
  .blog-container {
    display: flex;
    gap: 30px;
  }

  .breadcrumbs {
    margin-top: 8px;
    margin-bottom: 17px;
  }

  &__body-inner {
    padding: 16px 36px 36px;
  }

  &__top {
    @include utils.mx(-36px);
    @include utils.px(36px);
    padding-bottom: 16px;
    margin-bottom: 21px;
    @include utils.flex-between;
    @include utils.items-center;
    @include utils.bd-y(var(--blue200), 0, 3px);
  }

  &__stats {
    @include utils.items-center;
    gap: 24px;
  }

  &__date-wrapper {
    margin-bottom: 9px;
    @include utils.flex-between;
    @include utils.items-center;
  }

  &__date {
    font-size: 15px;
    color: var(--gray100);
  }

  &__subcategory {
    padding: 8px 20px;
    background-color: var(--blue100);
    font-size: 14px;
    font-weight: 600;
    color: var(--blue700);
    border-radius: 8px;
  }

  &__title {
    margin-bottom: 12px;
    text-transform: uppercase;
    font-size: 22px;
    font-weight: 800;
    line-height: 1.275;
    max-width: 700px;
  }

  &__cover {
    margin: 30px -36px;
    height: 290px;

    img {
      @include utils.img-size(100%, 100%)
    }
  }

  &__content {
    :is(p, blockquote) + h2 {
      margin-top: 45px;
    }

    h2 {
      margin-bottom: 14px;
      max-width: 850px;
      @include utils.flex-col;
      font-size: 22px;
      line-height: 1.46;
      text-transform: uppercase;
      font-weight: 700;
      color: var(--blue700);

      strong {
        color: var(--blue400);
        font-size: 16px;
        line-height: 1.5;
      }
    }

    blockquote {
      position: relative;
      margin: 35px 0;
      padding: 25px 46px 25px 118px;
      background-color: var(--blue100);
      border-radius: 16px;
      border: 0;
      box-shadow: none;
      font-style: normal;
      text-align: left;
      font-size: 17px;
      line-height: 1.6;

      &::before {
        content: '“';
        position: absolute;
        top: 15px;
        left: 36px;
        font-weight: 800;
        font-size: 96px;
        line-height: 80px;
        font-style: italic;
        letter-spacing: -0.01em;
        color: var(--blue400);
      }
    }

    ul {
      @include utils.clear-list;
      margin: 17px 0;
      @include utils.flex-col;
      gap: 14px;

      li {
        display: flex;
        gap: 8px;

        &::before {
          content: '';
          position: relative;
          top: 3px;
          display: block;
          flex-shrink: 0;
          @include utils.square(24px);
          @include utils.bg-img('media/static/icons/check.svg');
        }
      }
    }

    & > *:last-child {
      margin-bottom: 0;
    }
  }
}

@media only screen and (max-width: 960px) {
  .article-detail {
    &__stats {
      gap: 7px;
    }

    .article-stat__title {
      font-size: 0;
    }
  }
}

@media only screen and (max-width: 820px) {
  .article-detail {
    &__top {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    &__stats {
      grid-column: 2;
    }

    &__stats,
    .article-actions {
      justify-self: end;
    }
  }
}

@media only screen and (max-width: 700px) {
  .article-detail {
    &__title {
      font-size: 18px;
    }

    &__body-inner {
      padding: 20px;
    }

    &__top {
      @include utils.mx(-20px);
      @include utils.px(20px);
    }

    &__date-wrapper {
      margin-bottom: 25px;
      flex-direction: column;
      align-items: initial;
      gap: 15px;
    }

    &__subcategory {
      width: fit-content;
    }

    &__cover {
      @include utils.mx(-20px);
    }

    &__content {
      h2 {
        font-size: 16px;
      }

      blockquote {
        padding: 20px 20px 20px 70px;

        &::before {
          top: 8px;
          left: 15px;
          font-size: 75px;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .article-detail {
    &__top {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .article-actions {
      order: 3;
    }
  }
}

@media only screen and (max-width: 500px) {
  .article-detail {
    .article-actions__item {
      font-size: 13px;

      &::before {
        @include utils.square(20px);
      }
    }

    &__content {
      blockquote {
        padding: 20px;
        display: flex;
        flex-direction: column;

        &::before {
          position: relative;
          left: -8px;
          top: initial;
          margin-top: -15px;
          height: 45px;
        }
      }
    }
  }
}

@media only screen and (max-width: 370px) {
  .article-detail {
    .article-actions {
      flex-direction: column;
    }
  }
}